@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"


























































































.exam-modal

  .redirect
    display: flex
    align-items: center
    justify-content: center

  .modal
    box-sizing: border-box

    +mq-s
      min-width: auto
      padding: 16px

      .modal__close
        top: 8px
        right: 8px
        opacity: .6

  &__header
    background-image: url(~@/assets/blue-template-banner.png)
    max-height: 292px

  &__header-description
    margin-top: 8px

  &__header_image
    transform: translateY(-15px)

    +mq-s
      width: 100%
      transform: translateY(-10px)

  &__body
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding-bottom: 10px

    &_description
      font-size: $font-size-s
      color: $color-ink_light
      margin-top: 10px
      max-width: 70%
      text-align: center

    &-details
      padding: 32px 40px
      position: relative
      z-index: 1

      &::after
        content: ""
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0

        +mq-xs
          background: linear-gradient(90deg, white 30%, transparent 100%)

      +mq-s
        padding: 8px

      &__info
        position: relative
        z-index: 1
        margin-bottom: 24px

        .info-right__label
          font-size: 13px

    &-ilustration
      width: 328px

      +mq-s
        position: absolute
        right: -100px
        width: 320px

      +mq-xs
        right: -125px
        width: 280px

  &__footer
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    border-top: 1px solid $color-ink_lightest
    padding: 15px

    .btn--float
      text-decoration: none

